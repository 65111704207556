﻿/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(/fonts/lato/v11/dPJ5r9gl3kK6ijoeP1IRsvY6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  src: local('Lato Light'), local('Lato-Light'), url(/fonts/lato/v11/KT3KS9Aol4WfR6Vas8kNcg.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(/fonts/lato/v11/EsvMC5un3kjyUhB9ZEPPwg.woff2) format('woff2');
  src: local('Lato Light'), local('Lato-Light'), url(/fonts/lato/v11/KT3KS9Aol4WfR6Vas8kNcg.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/lato/v11/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
  src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/lato/v11/9k-RPmcnxYEPm8CNFsH2gg.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
  src: local('Lato Regular'), local('Lato-Regular'), url(/fonts/lato/v11/9k-RPmcnxYEPm8CNFsH2gg.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/lato/v11/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/lato/v11/wkfQbvfT_02e2IWO3yYueQ.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/lato/v11/H2DMvhDLycM56KNuAtbJYA.woff2) format('woff2');
  src: local('Lato Bold'), local('Lato-Bold'), url(/fonts/lato/v11/wkfQbvfT_02e2IWO3yYueQ.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}