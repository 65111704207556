﻿@import "bootstrap/_bootstrap.less";

        html, body{
            margin:0;
            background-color:#eee;
            
            //background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQIW2NkwAT/GdHE/jMwMDAiC4IFQIpggnABmCCKAEgQAABOBQW5VNBHAAAAAElFTkSuQmCC);
            //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAMAAAC6sdbXAAAABlBMVEX///////9VfPVsAAAAAXRSTlMAQObYZgAAAA1JREFUeAFjAAFGXAQAAGkABtmbFiMAAAAASUVORK5CYII=);
            background-repeat:repeat;
            background-color:#C7E0F5;
            
                        font-family: Lato;
                        overflow: hidden;

        }
        body{

        }
        header{
            position:fixed;
            top:0;
            left:0;
            width:100%;
            //height:2em;
            line-height: 2em;
            //background:rgba(255,255,255,.5);
            //background:#19388A;
            background:rgba(243,243,243,1);
            color:#333;
            z-index:1;
            font-size: .9em;
            padding-top:0.2em;
            

        }
        header .brand{
            color:#333;
            margin-left:1em;
            font-weight:bold;
        }
        header .brand img{
            padding-bottom:0.2em;
        }
        header .brand a{
            color:#333;
        }
        header .brand a:hover{
            text-decoration:none;
        }


        header .breadcrumbs{
            color:#333;
        }
        header>nav{
            /*float: right;*/
            position: absolute;
            width:100%;
            background: rgba(0,0,0,.3);
            padding:0 0 0 1em;
            background:rgba(255,255,255,.9);
            
        }

        header .tree{
           padding:1em 2em;
           clear:both;
           background-color:#eee;
           display:none;
           width:100%

        }


        ul.inpage{
            counter-reset: section;
            list-style: none;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            padding: 0;
            margin: 0;
            float:right;

        }
        ul.inpage>li{
            float: left;
            margin: 0 2em 0 0;
            position: relative;
            height:2em;

            font-size:1.2em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;



        }
        ul.inpage>li::before{
            counter-increment: section;
            content: counters(section,".") " ";   
            display: inline-block;
            background: rgba(0,0,0,.5);
            color:white;
            width:1em;
            height: 1em;
            line-height: .9em;
            text-align:center;
            margin: 0 .3em 0 0;
            font-weight: 700;
        }
        ul.inpage>li.decision::before{
            content: "B";   
        }
        ul.inpage>li.passthrough::before{
            content: "G";   
        }
        ul.inpage>li.active{
            color:#333;
            border-bottom:2px solid #333;
        }
        ul.inpage>li.active::before{
            background: #333;
            color:#fff;
        }
        ul.inpage>li>img{
            position:absolute;
            top:-300px;
            top:3em;

            right:0;
            width:80px;
            height:0;
            transition: all linear .5s;
            
        }
        ul.inpage>li.active>img{
            top:3em;
            height:136px;
        }
        .view{
            overflow:hidden;
            position:relative;
            /*height:calc(100vh - 4em);
            width:calc(100% - 2em);*/
            height:100vh;
            width:100%;
            padding:4em 1em 1em 1em;

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.45+100 */
//background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,60,0) 0%, rgba(0,0,0,60.10) 100%); /* FF3.6-15 */
//background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,60,0) 0%,rgba(0,0,60,0.10) 100%); /* Chrome10-25,Safari5.1-6 */
//background: radial-gradient(ellipse at center,  rgba(0,0,60,0) 0%,rgba(0,0,60,0.10) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


        }
        .view.intro{
            background-position:left bottom;
            background-repeat:no-repeat;
            overflow:auto;            
            background-size:40%;
            //background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.45) 100%); /* FF3.6-15 */
            //background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
            //background: radial-gradient(ellipse at center,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.45) 100%), url(img/johan.png) no-repeat; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
        .view.intro.entrance{
            background-size:auto;
        }
        .view>img{
            max-width:calc(100% - 4em);
            max-height:calc(100% - 8em);
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            border: 1em solid white;
            box-shadow: 0 0 20px rgba(0,0,0,.5);
        }
        .view .content,
        .view.intro.entrance .content{
            width:auto;
            height:auto;
            max-height:calc(100% - 4em);
            max-width:calc(100% - 2em);
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            overflow:auto;
            color:#000030;
            //text-shadow: 2px 2px 10px rgba(0,0,0,.9);
            background: rgba(255,255,255,.0);
            padding: 1em;
            white-space: nowrap;
            overflow:visible;

        }
        .view.intro {
            background-color:rgb(230,230,230);
            display:flex;
            align-items:center;
            justify-content:center;
        }
        .view.intro .content {
            flex:none;
            max-width:80%;
            position:relative;
            top:0;
            left:0;
            transform:none;
        }
        .view.intro.entrance .content{
            transform:translate(-30%, -50%);
        }
        
        .view .content h1{
            font-size: 5em;
        }
        .view .content h1.question{
            font-size: 2em;
            text-transform: uppercase;
            font-weight: 400;
            color:purple;
            text-shadow: none;
            white-space: normal;
        }
        .view .content p{
            font-weight: 300;
            font-size: 2.4em;
            white-space: normal;
            font-weight:bold;
        }     

        .view .content ul li a{
             font-weight: 300;
             font-size:2em;
             color:#000030;
             font-weight:bold;
        }

        .view .content.umbRichText h1{
            white-space: normal;
        }     
        
        .view .content.umbRichText li{
            font-weight: 300;
            font-size: 2em;
            max-width: 38em;
            white-space: normal;
        }     

  
        .view>.paper{
            /*height:calc(100% - 4em);
            width:calc(100% - 6em);*/
            height:calc(100% - 2em);
            width:calc(100% - 2em);

            margin:1em;
            padding:1em 2em 1em 1em;
            overflow: auto; 
                        background: radial-gradient(ellipse at center,  rgba(255,255,255,.9) 0%,rgba(255,255,255,.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            box-shadow: 5px 5px 20px rgba(0,0,0,.3);      
            position:relative; 
        }
.view .content ol{
            font-size: 5em;
            padding: 0 1em;
            margin: .5em 0;

}
.view ol>li a {
    color:#000030;
    
}
.view ol>li>.description{
font-size:.25em;
font-weight: 300;
text-transform: none;
}
.view .content ol>li.hidden{
    visibility:hidden;
}

.view .paper .content{
    text-shadow:none;
    color:#333;
    font-size: .8em;
}
.view .paper .content h1.question{
    color:#333;
}

.view .buttons{
    position:absolute;
    width:auto;
    bottom:1em;
}

.resultHistory{
    display:none;
}

.result{
    display:none;
}

.startVoting{
    display:none;
}

.stopVoting{
    display:none;
}

.blink{
    animation:blink 300ms infinite alternate;
}

#doLogout{
    padding-left:0.5em;
    cursor:pointer;
}

.pdf{

    height:100%;
    /*width:100%;*/
    margin:0 auto;
}

.fullscreen{
    margin-right:3em;
    margin-top:0.3em;
    cursor:pointer;
    float:right;

}


@keyframes blink {
    from { opacity:1; }
    to { opacity:0; }
}

.vote-options-description{
    font-size:.3em;
    font-weight:normal;
}

.voteProgress{
    position:absolute;bottom:1em;right:1em;
}

.choiceDetails{
    position:relative;
}
.choiceDetails>*{
    position:absolute;
}

