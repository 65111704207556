/*
 You probably do not need to edit this at all.

 Add some SmartMenus required styles not covered in Bootstrap 3's default CSS.
 These are theme independent and should work with any Bootstrap 3 theme mod.
*/
/* sub menus arrows on desktop */
.navbar-nav:not(.sm-collapsible) ul .caret {
	position: absolute;
	right: 0;
	margin-top: 6px;
	margin-right: 15px;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-left: 4px dashed;
}
.navbar-nav:not(.sm-collapsible) ul a.has-submenu {
	padding-right: 30px;
}
/* make sub menu arrows look like +/- buttons in collapsible mode */
.navbar-nav.sm-collapsible .caret, .navbar-nav.sm-collapsible ul .caret {
	position: absolute;
	right: 0;
	margin: -3px 15px 0 0;
	padding: 0;
	width: 32px;
	height: 26px;
	line-height: 24px;
	text-align: center;
	border-width: 1px;
 	border-style: solid;
}
.navbar-nav.sm-collapsible .caret:before {
	content: '+';
	font-family: monospace;
	font-weight: bold;
}
.navbar-nav.sm-collapsible .open > a > .caret:before {
	content: '-';
}
.navbar-nav.sm-collapsible a.has-submenu {
	padding-right: 50px;
}
/* revert to Bootstrap's default carets in collapsible mode when the "data-sm-skip-collapsible-behavior" attribute is set to the ul.navbar-nav */
.navbar-nav.sm-collapsible[data-sm-skip-collapsible-behavior] .caret, .navbar-nav.sm-collapsible[data-sm-skip-collapsible-behavior] ul .caret {
	position: static;
	margin: 0 0 0 2px;
	padding: 0;
	width: 0;
	height: 0;
	border-top: 4px dashed;
	border-right: 4px solid transparent;
	border-bottom: 0;
	border-left: 4px solid transparent;
}
.navbar-nav.sm-collapsible[data-sm-skip-collapsible-behavior] .caret:before {
	content: '' !important;
}
.navbar-nav.sm-collapsible[data-sm-skip-collapsible-behavior] a.has-submenu {
	padding-right: 15px;
}
/* scrolling arrows for tall menus */
.navbar-nav span.scroll-up, .navbar-nav span.scroll-down {
	position: absolute;
	display: none;
	visibility: hidden;
	height: 20px;
	overflow: hidden;
	text-align: center;
}
.navbar-nav span.scroll-up-arrow, .navbar-nav span.scroll-down-arrow {
	position: absolute;
	top: -2px;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	overflow: hidden;
	border-top: 7px dashed transparent;
	border-right: 7px dashed transparent;
	border-bottom: 7px solid;
	border-left: 7px dashed transparent;
}
.navbar-nav span.scroll-down-arrow {
	top: 6px;
	border-top: 7px solid;
	border-right: 7px dashed transparent;
	border-bottom: 7px dashed transparent;
	border-left: 7px dashed transparent;
}
/* add more indentation for 2+ level sub in collapsible mode - Bootstrap normally supports just 1 level sub menus */
.navbar-nav.sm-collapsible ul .dropdown-menu > li > a,
.navbar-nav.sm-collapsible ul .dropdown-menu .dropdown-header {
	padding-left: 35px;
}
.navbar-nav.sm-collapsible ul ul .dropdown-menu > li > a,
.navbar-nav.sm-collapsible ul ul .dropdown-menu .dropdown-header {
	padding-left: 45px;
}
.navbar-nav.sm-collapsible ul ul ul .dropdown-menu > li > a,
.navbar-nav.sm-collapsible ul ul ul .dropdown-menu .dropdown-header {
	padding-left: 55px;
}
.navbar-nav.sm-collapsible ul ul ul ul .dropdown-menu > li > a,
.navbar-nav.sm-collapsible ul ul ul ul .dropdown-menu .dropdown-header {
	padding-left: 65px;
}
/* fix SmartMenus sub menus auto width (subMenusMinWidth and subMenusMaxWidth options) */
.navbar-nav .dropdown-menu > li > a {
	white-space: normal;
}
.navbar-nav ul.sm-nowrap > li > a {
	white-space: nowrap;
}
.navbar-nav.sm-collapsible ul.sm-nowrap > li > a {
	white-space: normal;
}
/* fix .navbar-right subs alignment */
.navbar-right ul.dropdown-menu {
	left: 0;
	right: auto;
}